import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  error: false
}

export const globalSlice = createSlice({
  name: `globals`,
  initialState,
  reducers: {}
})

export default globalSlice
