import React from 'react';
import {Provider} from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

import store from './src/store';
import theme from './src/ui/themes/dark';

export const wrapRootElement = ({element, props}) => {
  return (
      <React.Fragment>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <Provider store={store} {...props}>
            {element}
          </Provider>
        </ThemeProvider>
      </React.Fragment>

  );
};
